// styles.scss
@import 'material-icons/iconfont/material-icons.css';
@import 'font-awesome/css/font-awesome.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css'; // Pre-built theme example


@import "override-bootstrap";
@import "override-material";
.error-message {
  color: maroon;
}
.error-dir-message {
  // position: fixed;
  // margin-top: 45px;
  color: red;
  font-size: 12px;
}
.warning {
  color: orange;
}
.break-div {
  height: 1px;
  border: 1px solid gray;
}
form {
  width: 100%;
}
/*.form-section {
  background-color: #fff;
  margin-top: 20px;
  padding-bottom: 20px;
}
  */
.form-div {
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #fff;
  padding-top: 20px;
}
.disableTab {
  pointer-events: none;
  cursor: default;
}
.padding-top-10px {
  padding-top: 10px;
}
.upper-case {
  text-transform: uppercase;
}
.cert-file {
  border: 1px solid lightgray;
  min-height: 30px;
  min-width: 125px;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 12px;
  padding: 4px;
  border-radius: 5px;
}

$logocolor1: #285f8c;
$logocolor2: #00c89f;
$headercolor: #055777;
$sidebar-background-color: #444d58;
$sidebar-background-color-hover: #00c89f;
$body-background-color: #f1f3f8;
$header-top-botom-color: #bcc5d0;
$second-color: #00c89f;
$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.mat-mdc-radio-group label {
  margin-bottom: 0 !important;
  margin-top: 5%;
}
.mat-mdc-dialog-container{
    max-height:96vh !important;
}


html {
  --mat-form-field-container-height: 45px !important;
  --mat-form-field-container-vertical-padding: 7px !important;
  --mat-form-field-container-text-size: 12px !important;
  --mat-form-field-outlined-label-text-populated-size: 12px !important;
  --mdc-outlined-text-field-label-text-size: 12px !important;
  --mat-form-field-container-height: 37px !important;
  --mat-form-field-container-text-font: $font-family;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, .12);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, .6);
  --mdc-protected-button-label-text-size: 13px;
  --mdc-filled-text-field-container-color: none;
  --mat-table-row-item-label-text-font: $font-family;
  --mat-table-row-item-label-text-size: 13px !important;
  --mat-table-header-headline-font: $font-family;
  --mat-select-trigger-text-font: $font-family;
  --mat-select-trigger-text-size: 12px;
  --mat-option-label-text-font: $font-family;
  --mat-option-label-text-size: 12px;
  --mat-menu-item-label-text-font: $font-family;
  --mat-menu-item-label-text-size: 14px;
  --mat-table-header-headline-size: 14px;
  --mdc-protected-button-label-text-font: $font-family;
  --mat-expansion-header-text-font: $font-family;
  --mdc-protected-button-label-text-weight: 400;
  --mdc-protected-button-label-text-tracking: normal;
}

/*.mat-mdc-tab-header {
  --mat-tab-header-active-hover-label-text-color: white !important;
  --mat-tab-header-label-text-tracking: .06em;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #00c89f !important;
}*/

.mat-form-field-appearance-outline .mat-mdc-form-field-wrapper,
.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  height: 37px !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  width: max(8px, var(--mdc-outlined-text-field-container-shape, var(--mat-app-corner-extra-small))) !important;
}

.mat-mdc-dialog-actions {
  float: right;
}


body {
  background-color: $body-background-color;
  /*font-family: "Open Sans", sans-serif !important;*/
  // font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
  //   helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji,
  //   segoe ui symbol, noto color emoji;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #151b1e;
  text-align: left;
  background-color: #eff3f8;
}

.fa {
  color: $logocolor1;
  font-weight: bold;
}

.fa-dialog-actions {
  color: $logocolor1 !important;
  font-weight: bold;
}

a {
  cursor: pointer;
}

.mat-mdc-input-element {
  caret-color: $logocolor1;
}

.mat-header {
  padding-left: 2px;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: #fff;
}

.mat-toolbar.mat-header {
  background-color: #fff;
}

.mat-toolbar-single-row {
  height: 55px !important;
  border-bottom: 1px solid $header-top-botom-color;
}

.content {
  min-height: 515px;
  font-size: 14px;
}

.app-content {
  word-wrap: break-word;
  position: relative;
  left: 0;
  right: 0;
  top: 47px !important;
}

.content-header {
  height: 40px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 5px;
}

.logo {
  width: 186px;
  height: 42px;
}

.no-padding {
  padding: 0 !important;
}

.container-fluid {
  padding: 0;
}

table {
  width: 100%;
}

.th.mat-mdc-header-cell {
  font-weight: bold;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding-right: 2px !important;
}

tr {
  &:hover {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.014), 0 3px 14px 2px rgba(0, 0, 0, 0.012);
  }
}

.mat-mdc-table tbody,
.mat-mdc-table tfoot,
.mat-mdc-table thead,
.mat-mdc-table-sticky,
[mat-footer-row],
[mat-header-row],
[mat-row],
mat-footer-row,
mat-header-row,
mat-row {
  background: none;
}

.mat-elevation-z8 {
  box-shadow: none;
  margin-bottom: 10px;
}

.mat-fab.mat-primary,
.mdc-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-raised-button.mat-primary:not(:disabled) {
  background-color: $logocolor1;
}

.mat-mdc-table {
  background: none;
}

.mat-paginator {
  background: none;
}

.mat-mdc-header-row {
  height: 45px !important;
}

.mat-mdc-row {
  height: 40px !important;
}

.mat-mdc-header-row,
mat-row {
  border-top: 1px solid lightgray;
}
//todo praveen
// .mat-expansion-panel:not(.mat-expanded)
//   .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-keyboard-focused,
// .mat-expansion-panel:not(.mat-expanded)
//   .mat-expansion-panel-header:not([aria-disabled="true"]).cdk-program-focused,
// .mat-expansion-panel:not(.mat-expanded)
//   .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
//   background-color: $second-color; //santhu
//   .fa {
//     color: #285f8c;
//     line-height: 22px;
//     width: 25px;
//   }
//   .mainMenuIcon {
//     color: white !important;
//   }
//   .mat-expansion-indicator::after,
//   .mat-expansion-panel-header-description {
//     color: white !important;
//   }
// }

em {
  color: red;
}

.mat-mdc-card-header {
  transition: left 0.25s, right 0.25s, width 0.25s;
  position: relative;
  border-bottom: 1px solid #bcc5d0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 5px;
  list-style: none;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  color: #151b1e;
  text-align: left;
}

.mat-checkbox-inner-container {
  height: 16px !important;
  width: 16px !important;
}

.mat-mdc-checkbox-checked .mdc-checkbox__background,
.mat-checkbox-indeterminate.mat-accent .mdc-checkbox__background {
  background-color: $logocolor1 !important;
  border-color: $logocolor1 !important;
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__inner-circle {
  background-color: $second-color !important; //$second-color; //santhu
  border-color: $logocolor1 !important;
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $logocolor1 !important;
}

.mat-mdc-form-field {
  font-size: 13px;
  // height: 54px;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-wrapper,
.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
  height: 44px;
}
//Santhosh Changed start
.mat-mdc-form-field-flex {
  min-height: 40px; //praveen Changed from height to min-height(for textarea)
}

.mat-mdc-form-field-label-wrapper {
  top: -1.4375em;
  padding-top: 1.44375em;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 0em 0 0em 0;
}

.mat-form-field-appearance-outline .mat-mdc-select-arrow -wrapper {
  padding-top: 9px;
}

.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
  color: brown;
}


.user-search {
  .mat-mdc-form-field .mat-mdc-form-field-infix {
    border-top: 0 solid transparent !important;
    min-height: 40px !important;
    padding-top: 20px;
  }

  .mat-mdc-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
    padding-left: 0px;
  }

  .mat-mdc-form-field {
    height: 50px;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  .mat-mdc-select-placeholder {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  }
}


.mat-form-field-appearance-outline.mat-mdc-form-field-invalid.mat-mdc-form-field-invalid .mat-mdc-form-field-outline-thick {
  color: brown !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-mdc-form-field-outline-thick {
  color: #3f51b5 !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: #3f51b5 !important;
}
//Santhosh Changed end
.mat-mdc-tab-link {
  min-width: 100px !important;
}

.mat-mdc-tab-links {
  background-color: #fff;
}

.mat-mdc-tab-links a {
  &:hover {
    background-color: $second-color !important; //$second-color; //santhu
    /*font-weight: 600;*/
    text-decoration: none;

    /*.mdc-tab__text-label {
      color: white !important;
    }*/
  }
}

.mdc-tab--active {
  /*background-color: $second-color !important;*/

  .mdc-tab__text-label {
    //$second-color; //santhu
    // background-color: $second-color; //$second-color; //santhu
    font-weight: 600;
    /*color: white !important;*/
  }
}

.app-footer {
  flex: 0 0 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 1rem;
  color: #151b1e;
  background: #f1f3f8;
  border-top: 1px solid #bcc5d0;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  text-align: center;
}

.main-page-content {
  margin: 18px !important;
  // padding: 10px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e8ec;
}

.mat-mdc-card {
  background-color: #f1f3f8 !important;
  // background-color: #e4e5e6 !important;
}

.mat-mdc-card-header-text {
  margin: 0 10px !important;
}

.mat-mdc-card:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.back-white {
  background-color: white !important;
}

.mat-expansion-panel-spacing {
  margin: unset !important;
}

.mat-expansion-panel-header {
  height: 48px !important;
}

.mat-expansion-indicator::after,
.mat-expansion-panel-header-description {
  color: #939fb1 !important;
}

.nav-menu .mat-expansion-panel-body div {
  padding: 9px 7px 6px 2px;
}

.nav-menu .mat-expansion-panel-header {
  padding-left: 24px !important;
}

a.sub-menu-items:hover {
  color: white !important;
}

.nav-menu .mat-expansion-panel-body {
  padding-bottom: 0;
  padding-left: 50px;
  padding-right: 2px;
  background-color: #363e46 !important;
}

.mat-mdc-menu-item:hover {
  background-color: $second-color !important; //santhu
  color: white !important;

  .nav-menu i {
    color: white !important;
  }

  .fa {
    color: white !important;
  }
}

.mat-mdc-table {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.mat-mdc-form-field {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.mat-mdc-card {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0 !important;
}

.panel-group {
  width: 100% !important;
}

button {
  margin-right: 8px !important;
}

.main-page-content .mat-toolbar-row {
  margin-top: 2px !important;
}

.mat-toolbar-multiple-rows {
  min-height: 64px !important;
}

.mat-primary .fa {
  color: white;
}

.mat-mdc-table {
  background-color: white !important;
}

.error-dir-message {
  position: absolute;
  margin-bottom: 17px;
  bottom: 100%;
  background: brown;
  left: 10%;
  color: #fff !important;
  font-size: 11px;
  -webkit-font-smoothing: auto;
  padding: 0 5px;
  line-height: 1.6;
}

.error-dir-message:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -6px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
  pointer-events: none;
}

input.mat-mdc-input-element {
  height: 20px;
}

#matDrawer.mat-drawer-container {
  background: none;
}

#matDrawer .mat-drawer-content {
  // min-height: 500px;
  overflow: hidden !important;
}

#matDrawer .mat-drawer.mat-drawer-side {
  position: fixed;
  margin-top: 55px;
  background-color: $sidebar-background-color !important;
}

#matDrawer .mat-drawer-side {
  height: 577px !important;
}

#matDrawer .mat-drawer-side {
  border-right: none !important;
}

.mat-cell,
.mat-mdc-footer-cell {
  font-size: 13px;
}

.mat-raised-button {
  line-height: 32px !important;
  padding: 0 12px !important;
}

.mat-mdc-button,
.mat-fab,
.mdc-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
  font-size: 13px;
}

.main-page-content.mat-mdc-card-content,
.main-page-content .mat-mdc-card-content,
.main-page-content .mat-mdc-card-content:first-child,
.main-page-content .mat-mdc-card-content:last-child {
  padding: 0px !important;
}

.mat-mdc-form-field .mdc-text-field--filled .mdc-floating-label {
  font-size: 12px;
}

.mat-form-field-underline,
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-raised-button {
  line-height: 20px !important;
  padding: 0 4px !important;
  height: 32px !important;
}

mat-form-field.mat-form-field-appearance-outline {
  margin-bottom: 15px;
}

.mat-datepicker-toggle button {
  margin-right: 0px;
}

.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    width: auto !important;
  }
}


.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0;
}

mat-datepicker-toggle {
  button {
    width: 26px !important;
    height: 26px !important;
  }

  .mat-mdc-button-base {
    padding: 6px !important;
  }
}

.user-search {
  mat-datepicker-toggle {
    button {
      font-size: 14px !important;
    }
  }

  .mat-mdc-button-base {
    padding: 4px !important;
  }

  .mat-mdc-icon-button {
    font-size: 14px !important;
  }

  .mat-mdc-icon-button img,
  .mat-mdc-icon-button svg {
    height: 14px;
    width: 14px;
  }
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0;
}

.mat-mdc-dialog-surface {
  padding: 25px;
}

hr {
  width: 100%;
}

.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 1px !important;
}

button.mdc-button i.fa {
  margin-right: 4px;
}

.mdc-button__label {
  display: contents;
}

td.mat-mdc-cell.mdc-data-table__cell.cdk-cell:first-child {
  padding-left: 4px !important;
}

th.mat-mdc-header-cell.mdc-data-table__header-cell.cdk-header-cell:first-child {
  padding-left: 4px !important;
}

.mat-mdc-table {
  margin-top: 1%;
}


.mat-form-field-appearance-outline .mat-mdc-text-field-wrapper{
    height:auto !important;
}
.mat-mdc-paginator {
  border-top: 1px solid lightgray;
  padding-top: 5px;
}

.mat-mdc-radio-group.ng-invalid.ng-touched .mat-mdc-radio-button .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle
{
  border-color: red;
}

.mat-mdc-radio-group.ng-invalid.ng-touched .mdc-label {
   color: #f44336;
}
.error-message mat-error {
  font-size: 12px;
  margin-top: -4px;
  padding: 0 16px;
}
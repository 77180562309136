/* You can add global styles to this file, and also import other style files */
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    /* Firefox 16+*/
  }

  100% {
    -moz-transform: rotate(360deg);
    /* Firefox 16+*/
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    /* Firefox 16+, IE 10+, Opera */
  }

  100% {
    transform: rotate(360deg);
    /* Firefox 16+, IE 10+, Opera */
  }
}



$danger: orange;
$primary: #00c89f;
$warning: yellow;

$logocolor1: #285f8c;
$logocolor2: #00c89f;
$headercolor: #055777;

.logocolor1 {
  color: $logocolor1;
}

.logocolor2 {
  color: $logocolor2;
}

.main-loader-sub {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
}

.lds-facebook {
  /* change color here */
  color: #00c89f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60000;
  background-color: #666666;
  opacity: 0.8;
  filter: alpha(opacity=70);
}

.lds-facebook,
.lds-facebook div {
  box-sizing: border-box;
}

.lds-facebook .main-loader-sub div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: currentColor;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div.main-loader-sub div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div.main-loader-sub div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div.main-loader-sub div:nth-child(3) {
  left: 56px;
  animation-delay: 0s;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.page-header {
  color: $logocolor1;
  font-size: 22px;
  border-bottom: 1px solid lightgray;
  height: 40px;

  i {
    color: #ffffff;
  }
}

.page-card-header {
  padding: 5px;
  margin-bottom: 0;
  background-color: #f1f3f8;
  border-bottom: 1px solid #e3e8ec;
  padding-left: 20px !important;
}

.profile-tab-content {
  border: 1px solid #e3e8ec !important;
}

.mat-mdc-card-actions,
.mat-mdc-card-content,
.mat-mdc-card-subtitle {
  margin-bottom: 0 !important;
}

.no-padding {
  padding: 0;
}









.dashboard-content {
  background-color: none !important;
}

.grid-statustype {
  font-weight: 600;
  padding: 4px;
  border-radius: 9px;
}

.text-green {
  color: green;
}

.text-orange {
  color: orange;
}

.text-red {
  color: red;
}

.width-30p {
  width: 30%;
}

.text-bold {
  font-weight: 600;
}

.final-rpt-icon {
  font-size: 35px;
}

.a-link {
  text-decoration: underline !important;
  color: #285f8c;
}

.a-link-ent {
  text-decoration: underline !important;
  color: #285f8c;
  font-weight: 600;
  font-size: 15px;
}

.reset-password-body {
  background-color: #fff;
  padding: 20px;
}

.email-cc {
  word-wrap: break-word;
  display: block;
  word-break: break-all;
}

.ent-status-th {
  width: 100px;
}

.fs-12x {
  font-size: 12;
  ;
}
.fs-20x {
  font-size: 20px;
}

.dv-gap-border {
  border-right: 20px solid #e3e8ec;
}

.fa-trash {
  color: rgb(169, 33, 33);
}

.cke_notifications_area {
  display: none;
}

.find-uan-actions {
  padding-top: 5px;
}